import { Component } from 'react'
import TopMenu from '../components/topMenu'
import moment from 'moment'
import QrReader from 'modern-react-qr-reader'
import withRouter from '../components/withRouter'
import Logo from '../images/GigLoca_Logo_Light.png'
import { API } from 'aws-amplify'
import { FaArrowLeft, FaUser } from 'react-icons/fa'
import { FcCancel, FcCheckmark } from 'react-icons/fc'
import './node.css'

class TixCheck extends Component {
    constructor(props) {
        super(props)

        this.state = {
            delay: 1000,
            data: null,
            scan: false,
            status: null,
            css: 'Small',
            msg: '',
            displayMsg: false,
            eid: null
        }
        this.handleResult = this.handleResult.bind(this)
        this.handleError = this.handleError.bind(this)
        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleDisplaySettings = this.handleDisplaySettings.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            this.setState({ splash: false, bounce: true })
            setTimeout(() => {
                this.handleNavigate('main')
            }, 3000)
        }
    }

    handleNavigate(page) {
        this.props.onAddHistory(`/tixcheck`)
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleBack() {
        this.props.onBack(this.state.refresh)
    }

    handleOnPrefSelect() {
        this.handleNavigate('profile')
    }

    handleDisplaySettings() {
        this.setState({ displaySettings: !this.state.displaySettings })
    }

    handleModeChange(mode) {
        this.setState({ displaySettings: false })
        this.props.setMode(mode)
        if (mode !== 'fan') {
            this.handleNavigate(`my/${mode}s`)
        } else this.handleNavigate('')
    }

    handleOnNotificationsSelect() {
        this.handleNavigate('notifications')
    }

    handleScan() {
        this.setState({ scan: !this.state.scan, status: null, data: null, css: 'Small' })
    }

    handleResult(result) {
        if (result) {
            this.setState({ scan: false })
            try {
                const ticket = JSON.parse(result)
                if (ticket.eid && ticket.tid && ticket.gid) {
                    API.put('lt', `tix/${ticket.eid}`, { body: { tid: ticket.tid, gid: ticket.gid, local: moment().format('YYYY-MM-DD HH:mm') } })
                        .then(resp => {
                            this.setState({ data: { ...resp }, eid: ticket.eid, status: resp.ticket.code ? 'code' : 'valid', css: 'Small' })
                            setTimeout(() => this.setState({ css: 'Big' }), 150)
                        })
                        .catch(e => {
                            this.setState({ data: e.response && e.response.data ? e.response.data : e.message, status: 'invalid', css: 'Small' })
                            setTimeout(() => this.setState({ css: 'Big' }), 150)
                            console.log(e)
                        })
                } else {
                    this.setState({ data: { error: 'Invalid Ticket' }, status: 'invalid', css: 'Small' })
                    setTimeout(() => this.setState({ css: 'Big' }), 150)
                }
            }
            catch(e) {
                this.setState({ data: { error: 'Invalid Ticket' }, status: 'invalid', css: 'Small' })
                setTimeout(() => this.setState({ css: 'Big' }), 150)
            }
        }
    }

    handleError(e) {
        console.log(e)
        //this.setState({ displayPost: false, post: '', isSaving: false, msg: e.message, displayMsg: true })
    }

    handleCodeConfirmed() {
        //if code is correct
        API.put('lt', `tix/${this.state.eid}`, { body: { tid: this.state.data.ticket.tid, gid: this.state.data.ticket.gid, code: true, local: moment().format('YYYY-MM-DD HH:mm') } })
            .then(resp => {
                this.setState({ data: { ...resp }, status: 'valid', css: 'Small' })
                setTimeout(() => this.setState({ css: 'Big' }), 150)
            })
            .catch(e => {
                this.setState({ data: e.response && e.response.data ? e.response.data : e.message, status: 'invalid', css: 'Small' })
                setTimeout(() => this.setState({ css: 'Big' }), 150)
                console.log(e)
            })
    }

    handleCodeFailed() {
        //if code is wrong
        this.setState({ data: 'Invalid Security Code', status: 'invalid', css: 'Small' })
        setTimeout(() => this.setState({ css: 'Big' }), 150)
    }

    getBounce() {
        return <div className='Pref-Bounce'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            <div className='Node-Content'>Become part of the fun by Logging In or Joining GigLoca.</div>
        </div>
    }

    getResponse() {
        return this.state.status === 'valid' || this.state.status === 'code' ? <div className='Node-Row'>
            <div className='Node-Row MyTix-Label'>{this.state.status === 'valid' ? 'Success' : 'Validate Code'}</div>
            {this.state.data && this.state.data.ticket && this.state.data.ticket.cat ? <div className='Node-Row MyTix-Label'>{this.state.data.ticket.cat}</div> : null}
            {this.state.data && this.state.data.ticket && this.state.data.ticket.label ? <div className='Node-Row MyTix-Label'>{this.state.data.ticket.label}</div> : null}
            {this.state.data && this.state.data.ticket && this.state.data.ticket.price ? <div className='Node-Row MyTix-Label'>${this.state.data.ticket.price}</div> : '$0.00'}
            {this.state.data && this.state.data.ticket && this.state.data.ticket.code ? <div className='Node-Row MyTix-Label' style={{ color: 'green', fontSize: '18pt', fontWeight: 'bold' }}>{this.state.data.ticket.code}</div> : null}
        </div> : <div className='Node-Row'>
            <div className='Node-Row MyTix-Label'>{this.state.data && this.state.data.error ? this.state.data.error : `Ticket Already Checked In`}</div>
            {this.state.data && this.state.data.ticket && this.state.data.ticket.checkedTime ? <div className='Node-Row MyTix-Label'>{`at ${moment(this.state.data.ticket.checkedTime).format('lll')}`}</div> : null}
            {this.state.data && this.state.data.ticket && this.state.data.ticket.checkedBy ? <div className='Node-Row MyTix-Label'>by</div> : null}
            {this.state.data && this.state.data.ticket && this.state.data.ticket.checkedBy ? <div className='Node-Row Node-Flex'><div style={{ marginRight: '10px' }}>{this.state.data.ticket.checkedByImage ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${this.state.data.ticket.checkedByImage}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div><div className='My-Link-Text'>{this.state.data.ticket.checkedBy}</div></div> : null}
        </div>
    }

    getReader() {
        return <div className={window.innerWidth > 700 ? 'Node-Outer' : ''}>
            <div className='Node-Content-2'>
                {this.state.scan && <div className='Node-Row Reader-Container'>
                    <QrReader
                        delay={this.state.delay}
                        onScan={this.handleResult}
                        onError={this.handleError}
                        facingMode={"environment"}
                        style={{ width: '100%', height: '100%' }}
                    />
                </div>}
                <div className='Node-Row'>
                    <div className={`Reader-Button Reader-Button-${this.state.scan ? 'Cancel' : 'Scan'}`} onClick={() => this.handleScan()}>{this.state.scan ? 'Cancel' : 'Scan'}</div>
                </div>
                <div className='Node-Row MyTix-Label'>{this.state.data ? this.getResponse() : 'Ready...'}</div>
                {this.state.status ? <div className='Node-Row'><div className={`QR-Status QR-${this.state.css}`}>
                    {this.state.status === 'code' ? <div className='Node-Row Node-Flex-Spread'>
                        <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleCodeConfirmed()}>Passed</div>
                        <div className='Pref-Button Pref-Cancel-Button' onClick={() => this.handleCodeFailed()}>Failed</div>
                    </div> : (this.state.status === 'valid' ? <FcCheckmark /> : <FcCancel />)}
                </div></div> : null}
            </div>
        </div>
    }

    render() {
        return (
            <div className='Node-Container Node-Check'>
                <TopMenu
                    mode='fan'
                    onMode={this.handleModeChange}
                    onPref={this.handleOnPrefSelect}
                    notifications={this.props.user ? (this.props.user.notifications ? this.props.user.notifications.length : true) : null}
                    onNotifications={this.handleOnNotificationsSelect}
                    user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                    onSettings={this.handleDisplaySettings}
                    displaySettings={this.state.displaySettings}
                    pref={null} />
                {
                    this.state.bounce ? this.getBounce() : <div>
                        <div className='Node-Row Node-Flex-Spread'>
                            <div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div>
                        </div>
                        {this.getReader()}
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(TixCheck)